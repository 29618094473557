<template>
  <div class="lg:max-w-2xl">
    <p class="font-bold text-lg py-3 mx-2 capitalize">personal info</p>
    <transition name="scale-up" appear class="relative">
      <div
        class="flex flex-wrap bg-beige p-4 shadow hover:shadow-lg border-none"
      >
        <div class="w-24 h-12">
          <p class="font-semibold text-black capitalize">university</p>
          <p v-if="!!university?.abbrev" class="text-black">{{ university.abbrev }}</p>
        </div>
        <div class="w-36 h-12">
          <p class="font-semibold text-black capitalize">email</p>
          <p class="text-black truncate">{{ email }}</p>
        </div>
        <div class="w-24 h-12">
          <p class="font-semibold text-black capitalize">class</p>
          <p class="text-black">{{ classOf }}</p>
        </div>
        <div class="w-36 h-12">
          <p class="font-semibold text-black capitalize">phone</p>
          <p class="text-black">{{ phone }}</p>
        </div>
        <div class="w-52 h-24">
          <p class="font-semibold text-black capitalize">bio</p>
          <p class="text-black line-clamp-3">{{ bio }}</p>
        </div>
      </div>
    </transition>
    <!-- <p class="font-bold text-lg py-3 mx-2 mt-4">recent activity</p>
    <div class="bg-beige p-4 shadow hover:shadow-lg max-w-lg border-none">
      <n-timeline>
        <n-timeline-item
        class="text-black"
          type="error"
          title="Amnesia?!"
          content="password reset"
          time="2019-12-02 09:12"
        />
        <n-timeline-item
        class="text-black"
          type="success"
          title="The start of something big"
          content="gave your first tutoring session"
          time="2019-05-13 12:58"
        />
        <n-timeline-item
        class="text-black"
          type="info"
          title="Fresh off the boat"
          content="signed in to Tutoruu for the first time"
          time="2018-04-03 20:46"
        />
      </n-timeline>
    </div> -->
  </div>
</template>

<script>
// import { NTimeline, NTimelineItem } from "naive-ui";
import { useRouter } from "vue-router";

export default {
  components: {
    // NTimeline,
    // NTimelineItem,
  },
  props: {
    noedit: Boolean,
    email: String,
    university: Object,
    classOf: String,
    phone: String,
    bio: String,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>