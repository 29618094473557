<template>
  <div>
    <modal :active="showModal" :key="key">
      <h2 class="text-xl font-bold mt-4 -mb-2 text-center">Pick a class</h2>
      <div class="flex items-center justify-center">
        <div class="mt-6 w-80">
          <search-dropdown
            @select="
              selected = $event;
              validateClass();
            "
            @reset="selected = null"
            name="class search"
            placeholder="Add classes"
            :request="{
              path: `https://api.tutoruu.com/api/class/search/${user.university._id}/`,
              body: {},
            }"
          ><vue-feather class="absolute" stroke="grey" type="help-circle" size="20">
            </vue-feather></search-dropdown>
           
          <span class="text-xs font-extralight text-red-600">{{
            errors.course
          }}</span>
        </div>
      </div>
      <div class="w-full flex justify-end">
        <button
          class="
            py-2
            mt-4
            px-5
            relative
            text-center
            rounded-2xl  
            font-bold
            bg-orange
            text-white
          "
          @click="addClass()"
        >
          <p class="mx-auto my-0 py-0 text-lg font-bold capitalize">confirm</p>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { isUniqueClass, required } from "../helpers/validations";
import searchDropdown from "./inputs/searchDropdown.vue";
import modal from "./modal.vue";

export default {
  components: { searchDropdown, modal },
  computed: {
    ...mapState(["user"]),
  },
  props: {
    showmodal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.showmodal,
      key: 0,
      selected: null,
      errors: {
        course: null,
      },
    };
  },
  methods: {
    ...mapActions(["get_class", "enroll"]),
    validateClass() {
      let missing = required(this.selected);
      let repeats = this.user
        ? isUniqueClass(this.selected, this.user.classes)
        : false;
      this.errors.course = missing ? missing : repeats ? repeats : null;
    },
    async addClass() {
      this.validateClass();
      if (!this.errors.course) {
        this.enroll({ course: this.selected });
        this.showModal = false;
        this.key++;
      }
    },
  },
};
</script>
