<template>
  <n-card
    style="margin-bottom: 10px"
    class="bg-beige shadow hover:shadow-lg lg:w-96 mr-8 border-none rounded-2xl my-8 relative"
  >
    <n-button style="float: right" ghost type="primary" class="-my-1 rounded-full  "
      ><p class="text-xs lg:text-md font-bold capitalize" @click="() => getClassTutors()">
        browse tutors
      </p></n-button
    >
    <div style="margin-top: -3px" >
      <n-h3 style="margin: -2px 0px -1px 0px" class="font-bold truncate"
        ><n-text class="truncate capitalize">{{ course.name }}</n-text></n-h3
      >
    </div>

    <n-text depth="3">{{ course.desc }}</n-text>
    <n-divider style="margin: 7px 0px"></n-divider>
    <div class="flex ">
      <div>

      <n-text type="primary" strong
        >{{ course.students.length }}<n-text> Students</n-text></n-text
      >
    </div>
    <div class="lg:mx-6 mx-6">

    <n-text type="primary" strong
      >{{ course.courses.length }}<n-text> tutors</n-text></n-text
    >
    </div>
    </div>
  </n-card>
</template>

<script>
import { useRouter } from "vue-router";
import {
  NText,
  NCard,
  // NAvatar,
  NH3,
  NButton,
  NDivider,
  // NSpace,
} from "naive-ui";
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState(['username'])
  },
  components: {
    NText,
    // NAvatar,
    NH3,
    NButton,
    NDivider,
    NCard,
    // NSpace,
  },
  props: {
    course: Object,
  },
  methods: {
    ...mapMutations(['setClassPreviewed']),
    getClassTutors(){
      this.setClassPreviewed(this.course);
      this.$router.push(`/results?class=${this.course.desc}`);
    }
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>