<template>
  <page-layout title="Student Profile">
    <n-card>
      <n-space align="flex-end">
        <n-avatar
          round
          :size="54"
          src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg"
        />
        <div>
          <h2 class="font-bold text-xl">Youssef Abdelhamid</h2>
          <p>auc '23</p>
        </div>
      </n-space>
      <br />
      <div
        class="left-5 right-5 px-2 shadow-lg h-14 absolute rounded-3xl"
      ></div>
      <n-tabs
        type="bar"
        justify-content="space-evenly"
        size="large"
        tab-style="font-weight: bold; padding: 16px 12px; margin: auto;"
      >
        <n-tab-pane name="about" tab="about">
          <about-profile noedit />
        </n-tab-pane>
        <n-tab-pane name="classes" tab="classes">
          <profile-classes noedit />
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </page-layout>
</template>

<script>
import { NCard, NTabs, NTabPane, NSpace, NAvatar } from "naive-ui";
import PageLayout from "../components/base/pageLayout.vue";
import AboutProfile from "../components/aboutProfile.vue";
import ProfileClasses from "../components/ProfileClasses.vue";
import { mapState } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "userProfile",
  components: {
    NCard,
    NTabs,
    NTabPane,
    NSpace,
    NAvatar,
    PageLayout,
    AboutProfile,
    ProfileClasses,
  },
  computed: {
    ...mapState(["tutors"]),
    // ...mapGetters(['tutorByUsername']),
    user() {
      return this.users.find((user) => user.username === this.username);
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { username } = route.params;
    return { username, router };
  },
};
</script>
